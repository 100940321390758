:root {
    --apple: #8dc63f;
    --ice-blue: #f0f9f8;
    --purplish-grey: #696871;
    --dark-grey: #19191b;
    --white: #ffffff;
    --brownish-grey: #626262;
    --pale-grey: #f4f7fa;
    --text-grey: #161c2d;
}


.content {
    flex: 1 0 auto;
}

.button {
    display: inline-block;
    padding: 17px 34px 17px 34px;
    border-radius: 8px;
    background-color: var(--apple);
    color: var(--white);

    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.5px;
    text-align: center;

    cursor: pointer;
}
.button:hover {
    background-color: #729c38;
}

.brownish-grey {
    color: var(--brownish-grey);
}
