.footer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.site-map {
  width: 100%;
  background-color: var(--ice-blue);
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow: hidden;
}

.site-map-mobile {
  width: 100%;
  background-color: var(--ice-blue);
  display: flex;
  flex-direction: column;
}

.footer-col-logo {
  margin: 36px 28px 0px 28px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.footer-col-logo-mobile {
  padding: 10px 0px;
  margin: 28px;
  overflow: hidden;
}

.footer-hr {
  height: 0.5px;
  margin: 10px 24.5px 10px 28.5px;
  background-color: #707070;
}

.footer-social {
  width: 90px;
}

.footer-social-mobile {
  margin-top: 10px;
}

.footer-col-small {
  margin: 40px 100px 40px 42px;
  display: flex;
  flex-direction: column;
  height: 100%;
  font-size: 17px;
}

.footer-col-small-mobile {
  margin: 0px;
}

.footer-col-small a {
  margin-left: 0;
  color: var(--brownish-grey);
  font-size: 14px;
  text-decoration: none;
  padding-top: 20px;
}

.footer-col-small .location {
  font-size: 14px;
  color: var(--brownish-grey);
  padding-top: 31px;
  word-break: break-all;
}

.footer-2-col {
  margin: 28px;
  display: flex;
  font-size: 17px;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: auto;
}

.our-office {
  margin-left: 28px;
}

.our-office-mobile {
  margin: 28px;
}

.banner {
  display: flex;
  padding: 26px 0px;
  background-color: var(--apple);
  width: 100%;

  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: var(--white);

  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.banner-small-font {
  font-size: 13px;
}

.link:hover {
  text-decoration: underline;
}
